*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

article, footer, main {
  display: block;
}

body {
  font-family: var(--ff-primary);
  color: var(--clr-black);
  text-align: left;
  background-color: var(--clr-white);
  margin: 0;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 28px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 28px;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul ul {
  margin-bottom: 0;
}

strong {
  font-weight: bolder;
}

sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -.5em;
}

a {
  color: var(--clr-purple);
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: underline;
}

a:hover {
  color: var(--clr-magenta);
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  margin: 0;
  overflow: visible;
}

button, html [type="button"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

template {
  display: none;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
  font-family: var(--ff-secondary);
  margin-bottom: .5rem;
}

h1, .h1 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 70px;
}

h2, .h2 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 50px;
}

h3, .h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 34px;
}

h4, .h4 {
  font-size: 1.225rem;
  font-weight: 700;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

hr {
  border: 0;
  border-top: 1px solid var(--clr-light-gray);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 992px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1200px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.col-sm-6, .col-sm-12, .col-md-6, .col-md-12, .col-lg-3, .col-lg-4, .col-lg-6, .col-lg-9, .col-lg-12, .col-xl-3, .col-xl-4, .col-xl-6, .col-xl-9, .col-xl-12 {
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

@media (width <= 575.98px) {
  .col-xs-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xs-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (width >= 576px) {
  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (width >= 768px) {
  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (width >= 992px) {
  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (width >= 1200px) {
  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.fade.show {
  opacity: 1;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.media {
  align-items: flex-start;
  display: flex;
}

.close {
  float: right;
  color: #2e3d62;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
}

.close:hover, .close:focus {
  color: #2e3d62;
  opacity: .75;
  outline: none;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  -webkit-appearance: none;
  background-color: #0000;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0);
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #2e3d6233;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #2e3d62;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 2;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (width >= 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (width >= 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (width >= 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (width >= 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (width >= 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.align-self-center {
  align-self: center !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-white {
  color: #fff !important;
}

@media print {
  *, :before, :after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }
}

/*# sourceMappingURL=index.63ce9f8d.css.map */
